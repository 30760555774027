.hide {
  display: none;
  visibility: hidden;
}

.formOpen {
  max-height: 2500px; /* If all options displayed, content will be less than 2500px */
}

.formClosed {
  max-height: 0px;
}

input, select{
  width: 100%;
  height: 2rem;
  text-align: center;
  margin: 1rem 0;
  background: var(--background);
  color: var(--color);
}

.words-with-letters {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 8rem);
  padding: 4rem;
  overflow-y: scroll;
  background: var(--background);
  color: var(--color);
}

h1 {
  text-align: center
}

.desc {
  text-align: center
}

.input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.loading {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border: 3px solid rgba(128,128,128,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 1.125rem;
  right: 0.125rem;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.options .optionsTitle{
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.options .optionsTitle .arrowDown {
  transform: scaleY(-1) translateY(0.125em)
}

.options .optionsTitle .arrowUp {
  transform: translateY(0.125em)
}

.options>div:before, .options>div:after {
  background-color: var(--color);
  content: '';
  display: inline-block;
  height: 2px;
  position: relative;
  top: 0.5em;
  width: calc(50% - 70px);
}

.options>div:before {
  left: calc(50% + 35px);
}
.options>div:after {
  right: calc(50% + 35px);
}

.options form {
  width: calc(100% - 2rem);
  padding: 0 18px;
  overflow-y: hidden;
  transition: max-height 0.5s ease-out;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.options form div {
  flex: 1 1 0px;
  margin-top: auto;
  min-width: 10rem;
}

.results {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.word {
  display: flex;
}

.word:before {
  color: var(--color);
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.word .highlight {
  color: var(--highlight)
}

.points {
  font-size: 0.5em;
  align-self: end;
}

.button {
  color: var(--highlight);
  width: 100%;
  height: 2em;
  border: none;
  border-radius: 10%;
}
.button:hover {
  filter: brightness(0.9)
}

.wordle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wordle form{
  width: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wordle form .positionalInput {
  display: flex;
  justify-content: center;
}

.wordle form .positionalInput input {
  width: 2rem;
  height: 2rem;
}

.wordle form .yellowPositionalInput div {
  display: flex; 
}

.wordle form .yellowPositionalInput span {
  width: 9rem;
  line-height: 4.375rem;
}

.wordle form .checkbox {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 1rem;
}

.wordle form .checkbox div {
  line-height: 2rem;
}

.wordle form .checkbox input {
  height: 2rem;
  width: 2rem;
  margin: 0;
}

.wordle .guess {
  display: flex;
  flex-direction: column;
  align-items: center
}

.wordle .guess button {
  width: 9rem;
}

.wordle .guess > div {
  position: relative;
  padding-left: 2rem;
  width: 11rem;
}

.smallLoading {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 3px solid var(--highlight);
  border-radius: 50%;
  border-top-color: var(--color);
  animation: spin 1s linear infinite;
  position: absolute;
  top: 0.125rem;
  right: 0.125rem;
}